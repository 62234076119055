<template>
  <div>
    <WebInput
      v-if="!hideFullNameInput"
      v-model="fullName"
      :class-name="inputClass"
      :tab-index="inputTabIndex"
      :placeholder="translate('generate.placeholder.fullName', locale)"
      name="Fullname"
      type="text"
      :label="getInputLabel(translate('generate.label.fullName', locale), isLabelVisible)"
      :label-style="labelStyle"
      :disabled="isSubmitDisabled || isSubmitLoading || disabled"
      rules="required|min:3|max:100"
      :page-options="pageOptions"
      autocomplete="on"
      @error="handleInputError"
    >
      <template #left>
        <svg
          v-if="showInputIcons"
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="mr-8 text-neutral-500"
        >
          <path
            d="M12.0002 12C14.7616 12 17.0002 9.76142 17.0002 7C17.0002 4.23858 14.7616 2 12.0002 2C9.23873 2 7.00016 4.23858 7.00016 7C7.00016 9.76142 9.23873 12 12.0002 12Z"
            fill="currentColor"
          />
          <path
            d="M12.0002 14.5C6.99016 14.5 2.91016 17.86 2.91016 22C2.91016 22.28 3.13016 22.5 3.41016 22.5H20.5902C20.8702 22.5 21.0902 22.28 21.0902 22C21.0902 17.86 17.0102 14.5 12.0002 14.5Z"
            fill="currentColor"
          />
        </svg>
      </template>
    </WebInput>
    <WebInput
      v-if="registerType === 'phoneNumber'"
      v-model="subscriberId"
      type="phone"
      phone-with-country-select
      emit-outside-on-enter
      :class-name="inputClass"
      :tab-index="inputTabIndex"
      :placeholder="translate('generate.placeholder.phoneNumber', locale)"
      :name="`${name}_PhoneNumber`"
      :label="getInputLabel(translate('generate.label.phoneNumber', locale), isLabelVisible)"
      :label-style="labelStyle"
      :disabled="isSubmitDisabled || isSubmitLoading || disabled"
      :rules="`required|phoneFormat:${currentCountryCode}`"
      :page-options="pageOptions"
      autocomplete="on"
      :country="countryCode"
      :phone-number-allowed-countries="phoneNumberAllowedCountries"
      :locale="locale"
      @error="handleInputError"
      @country-change="handleCountryChange"
    />
    <WebInput
      v-else
      v-model="subscriberId"
      :class-name="inputClass"
      :tab-index="inputTabIndex"
      :placeholder="translate('generate.placeholder.email', locale)"
      :name="`${name}_Email`"
      type="email"
      :label="getInputLabel(translate('generate.label.email', locale), isLabelVisible)"
      :label-style="labelStyle"
      :disabled="isSubmitDisabled || isSubmitLoading || disabled"
      rules="required|email"
      :page-options="pageOptions"
      autocomplete="on"
      @error="handleInputError"
    >
      <template #left>
        <svg
          v-if="showInputIcons"
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="mr-8 text-neutral-500"
        >
          <path
            d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
            fill="currentColor"
          />
        </svg>
      </template>
    </WebInput>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, computed, toRefs, watch } from 'vue';
import { useTranslate } from '@shared/composable/useTranslate';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import WebInput from '@shared/components/input/index.vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';

const emit = defineEmits(['update:subscriberId', 'update:fullName', 'error']);
const props = defineProps({
  locale: { type: String, default: '' },
  isEditorMode: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  name: { type: String, default: 'RegisterInput' },
  element: { type: Object as PropType<ElementType<'registration' | 'payment' | 'payment-with-tabs'>>, default: () => ({}) },
  config: { type: Object as PropType<ElementOptions['registration']>, default: () => ({}) },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  subscriberId: { type: String, default: '' },
  fullName: { type: String, default: '' },
  hideFullNameInput: { type: Boolean, default: false }
});

const { element, pageOptions } = toRefs(props);

const countryCode = computed(() => getCurrentCountryCode());
const phoneNumberAllowedCountries = computed(() => props.pageData?.phoneNumberAllowedCountries || []);
const registerType = computed(() => props.pageData?.registerType);
const isSubmitLoading = computed(() => props.pageData?.isSubmitLoading);
const isSubmitDisabled = computed(() => props.pageData?.isSubmitDisabled);

const { translate } = useTranslate();
const { labelStyle, isLabelVisible } = useLabelOptions({ element, pageOptions });

const subscriberId = ref(props.subscriberId);
const fullName = ref(props.fullName);
const currentCountryCode = ref(countryCode.value);

const showInputIcons = computed(() => !!props.config.input?.showIcon);
const inputTabIndex = computed(() => (props.isEditorMode ? '-1' : undefined));
const inputClass = computed(() => ({
  'mb-32': true,
  'pointer-events-none': props.isEditorMode
}));

function getCurrentCountryCode() {
  const code = props.pageData?.countryCode;
  if (props.isEditorMode) return code;
  const allowedCountries = phoneNumberAllowedCountries?.value?.sort();
  if (allowedCountries.includes(code)) return code;
  // If not in allowed countries for phone return first country
  return allowedCountries?.[0];
}

watch(
  () => subscriberId.value,
  (val) => emit('update:subscriberId', val)
);

watch(
  () => fullName.value,
  (val) => emit('update:fullName', val)
);

watch([props.subscriberId, props.fullName], ([subscriberIdValue, fullNameValue]) => {
  if (subscriberIdValue !== subscriberId.value) {
    subscriberId.value = subscriberIdValue;
  }
  if (fullNameValue !== fullName.value) {
    fullName.value = fullNameValue;
  }
});

function getInputLabel(label = '', show = true) {
  return show ? label : '';
}

function handleInputError(errMessage: string) {
  emit('error', errMessage);
}

function handleCountryChange(code: string) {
  currentCountryCode.value = code;
}
</script>
